import { defineStore } from 'pinia';

import idMaker from '@/helpers/idMaker';

import {
  type ReservationMethod,
  type PartnerConfiguration,
  type DaysReservationAvailability,
  type Appointment,
  type ReservationKind as IReservationKind,
  type ContactInformation as IContactInformation,
  type Conditions as IConditions,
  type PolicySchema,
  type LocalAppointment,
  type ActivityLocation,
  type PartnerConfigurationActivity,
  type AppointmentBlock,
  type BigboxReservationMethod,
  type ExternalReservationMethod,
  type WonomaServices,
  type WonomaInformation,
  type OnlineReservationMethods,
  type WokiInformation,
} from '@/models/ReservationConfigurationInterface';

import { type ExternalForm } from '@/models/ProfileInterface';
import type { PartnerLocation } from '@/models/ProfileInterface';

import ReservationConfigurationApiService from '@/api/reservationConfiguration';
import LocksApiService from '@/api/locks';
import { axiosApi } from '@/config/http';
import { useAlertsStore } from '@/stores/alertsStore';

const idGenerator = idMaker();

export const useReservationConfigurationStore = defineStore(
  'reservationConfiguration',
  {
    state: () => ({
      panel_check_activity_locations: [] as PartnerLocation[],
      partnerActivities: [] as PartnerConfigurationActivity[],
      generalReservationConfigId: '',
      completedFields: {
        isContactInformationCompleted: false,
        isReservationKindCompleted: false,
        isNotificationMethodCompleted: false,
        isDefaultConfigurationCompleted: false,
      },
      contactInformation: {
        email: '',
        emailEnabled: false,
        phone: '',
        phoneEnabled: false,
      },
      reservationKind: {
        onlineReservation: false,
        manualReservation: false,
      },
      conditions: {
        anticipationToCancel: 0,
        anticipationToReserve: 0,
        culminationToReserve: 0,
        specialClarification: '',
      },
      covermanagerInformation: {
        slug: '',
        phone: '',
        email: '',
        isSlugVerified: false,
      },
      roomcloudInformation: {
        hotelId: '',
        roomId: '',
        roomName: '',
        roomprice: 0,
        rateId: '',
        nigths: 0,
        checkinTime: '',
        isCompleteConfiguration: false,
      },
      wonomaInformation: {} as WonomaInformation,
      wonomaServices: [] as WonomaServices[],
      wokiInformation: {} as WokiInformation,
      hasWalkin: false,
      locationSelected: '',
      activitySelected: '',
      activityLocationSelected: {} as ActivityLocation,
      reservationMethods: [],
      availableMethods: {
        id: '',
        status: '',
        logo: '',
        name: '',
      },
      bigboxNewReservations: {} as BigboxReservationMethod,
      newReservations: [] as ExternalReservationMethod[],
      activeReservationMethod: '' as string | undefined,
      policySchema: {} as PolicySchema,
      copyableLocations: [] as PartnerLocation[],
      availableLocks: [] as AppointmentBlock[],
      mutationStatus: {},
      reservationConfigurations: [] as PartnerConfiguration[],
      selectedReservationConfiguration: null as PartnerConfiguration | null,
      selectedAvailableDaysAndTimeSlots:
        [] as DaysReservationAvailability<LocalAppointment>[],
      availableTimeSlotsSavePromises: [],
      newConfigurationId: null as string | null,
      externalForms: [] as ExternalForm[],
      onlineReservationMethods: {} as OnlineReservationMethods,
    }),
    actions: {
      async getPartnerActivities() {
        const response =
          await ReservationConfigurationApiService.getPanelConfigurationActivities();
        const activities = response.data.panel_configuration_activities;
        this.partnerActivities = activities;
      },

      async getPartnerActivityLocations(activityId: string) {
        const response =
          await ReservationConfigurationApiService.checkActivityLocations({
            activity: activityId,
          });
        const locations = response.data.panel_check_activity_locations;
        this.panel_check_activity_locations = locations;
      },
      async getReservationConfigurationStatus({
        activityId,
        locationId,
      }: {
        activityId: string;
        locationId: string;
      }) {
        const response =
          await ReservationConfigurationApiService.initialReservationConfiguration(
            {
              partneractivity_id: activityId,
              location_id: locationId,
            },
          );
        this.activitySelected = activityId;
        const generalReservationConfigId =
          response.data.reservation_configuration_status.bigbox_reservation?.id;
        this.generalReservationConfigId = generalReservationConfigId;
        this.hasWalkin =
          response.data.reservation_configuration_status.bigbox_reservation
            ?.has_walkin;
        this.activeReservationMethod =
          response.data.reservation_configuration_status
            ?.active_reservation_method;
        this.bigboxNewReservations = {
          ...response.data.reservation_configuration_status?.bigbox_reservation,
        };
        this.newReservations = [
          ...(response.data.reservation_configuration_status
            ?.reservation_methods ?? []),
        ] as ExternalReservationMethod[];
      },

      async getCompletedFields({
        generalReservationConfigId,
      }: {
        generalReservationConfigId: string;
      }) {
        const response =
          await ReservationConfigurationApiService.getCompletedFields({
            general_reservation_config_id: generalReservationConfigId,
          });

        const completedFieldsData = response.data.completed_fields;
        const completedFields = {
          isContactInformationCompleted:
            completedFieldsData.is_contact_information_completed,
          isReservationKindCompleted:
            completedFieldsData.is_kind_reservation_completed,
          isNotificationMethodCompleted:
            completedFieldsData.is_notification_method_completed,
          isDefaultConfigurationCompleted:
            completedFieldsData.is_default_configuration_completed,
        };
        this.completedFields = completedFields;
      },
      async getContactInformation({
        activityId,
        locationId,
      }: {
        activityId: string;
        locationId: string;
      }) {
        const response =
          await ReservationConfigurationApiService.getContactInformation({
            partneractivity_id: activityId,
            location_id: locationId,
          });
        const contactInformationData = response.data.reservation_config;
        const contactInformation = {
          email: contactInformationData.email.email,
          emailEnabled: contactInformationData.email.enabled,
          phone: contactInformationData.phone.phone,
          phoneEnabled: contactInformationData.phone.enabled,
        };
        this.contactInformation = contactInformation;
      },

      async getReservationKind({
        activityId,
        locationId,
      }: {
        activityId: string;
        locationId: string;
      }) {
        const response =
          await ReservationConfigurationApiService.getReservationKind({
            partneractivity_id: activityId,
            location_id: locationId,
          });

        const reservationKindData = response.data.reservation_config;
        const reservationKind = {
          onlineReservation: reservationKindData.online_reservation,
          manualReservation: reservationKindData.manual_reservation,
        };
        this.reservationKind = reservationKind;
      },

      async getOnlineMethodsReservation({
        reservation_config_id,
        partner_user_id,
      }: {
        reservation_config_id: string;
        partner_user_id: string;
      }) {
        const alertsStore = useAlertsStore();
        axiosApi
          .get('v1/notifications', {
            params: {
              reservation_config_id,
              partner_user_id,
            },
          })
          .then(response => {
            const onlineConfigData = response?.data?.response;
            const onlineReservationMethods = {
              isWhatsappNotificationEnabled:
                onlineConfigData?.is_whatsapp_notification_enabled,
              isEmailNotificationEnabled:
                onlineConfigData?.is_email_notification_enabled,
              whatsappRecipientsMax: onlineConfigData?.whatsapp_recipients_max,
              emailRecipientsMax: onlineConfigData?.email_recipients_max,
              whatsappList: onlineConfigData?.whatsapp_list || [],
              emailList: onlineConfigData?.email_list || [],
            };
            this.onlineReservationMethods = onlineReservationMethods;
            if (
              onlineReservationMethods.isEmailNotificationEnabled ||
              onlineReservationMethods.isWhatsappNotificationEnabled
            ) {
              this.completedFields.isNotificationMethodCompleted = true;
            }
          })
          .catch(errors => {
            alertsStore.addReplace({
              type: 'error',
              message: errors?.message,
            });
          });
      },

      async getAvailableMethods({ locationId }: { locationId: string }) {
        const response =
          await ReservationConfigurationApiService.getAvailableReservationMethods(
            {
              partner_location_id: Number(locationId),
            },
          );
        if (response.data.reservation_methods_available) {
          const methodsData = response.data.reservation_methods_available;
          const availableMethods = {
            id: methodsData.id,
            status: methodsData.status,
            logo: methodsData.partner_reservation_method.logo,
            name: methodsData.partner_reservation_method.name,
          };
          this.availableMethods = availableMethods;
        } else {
          this.availableMethods = {} as ReservationMethod;
        }
      },
      async getAvailableLocks(reservationId: string | null) {
        const response = await LocksApiService.getBlockedAppointments({
          reservation_config_id: reservationId,
        });
        if (response.data.blocked_appointments) {
          this.availableLocks = response.data.blocked_appointments;
        } else {
          this.availableLocks = [];
        }
      },

      async getExternalForms() {
        const response =
          await ReservationConfigurationApiService.getExternalForms();
        this.externalForms = response.data.external_form;
      },

      async getConditions({
        activityId,
        locationId,
      }: {
        activityId: string;
        locationId: string;
      }) {
        const response = await ReservationConfigurationApiService.getConditions(
          {
            partneractivity_id: activityId,
            location_id: locationId,
          },
        );

        const reservationConfigData = response.data.reservation_config;
        const conditionsInformation = {
          anticipationToCancel: reservationConfigData.anticipation_to_cancel,
          anticipationToReserve: reservationConfigData.anticipation_to_reserve,
          culminationToReserve: reservationConfigData.culmination_to_reserve,
          specialClarification: reservationConfigData.special_clarification,
        };
        this.conditions = conditionsInformation;
      },

      async getPolicySchema() {
        const response =
          await ReservationConfigurationApiService.getPolicySchema({
            general_reservation_config_id: this.generalReservationConfigId,
          });

        this.policySchema = response.data.partner_policy_schema;
      },

      async getReservationConfigurations(generalReservationConfigId: string) {
        const response =
          await ReservationConfigurationApiService.getConfigurations({
            reservation_config_id: generalReservationConfigId,
          });
        const reservationConfigData = response.data.partner_configurations;
        this.reservationConfigurations = reservationConfigData;
      },

      async getCovermanagerInformation(generalReservationConfigId: string) {
        const response =
          await ReservationConfigurationApiService.getCovermanagerInformation({
            reservation_config_id: generalReservationConfigId,
          });

        const configurationResponse = response.data.covermanager_information;
        const covermanagerInformation = {
          slug: configurationResponse.slug,
          phone: configurationResponse.phone_config,
          email: configurationResponse.email_config,
          isSlugVerified: configurationResponse.is_slug_verified,
        };
        this.covermanagerInformation = covermanagerInformation;
      },

      async getRoomcloudInformation(generalReservationConfigId: string) {
        const response =
          await ReservationConfigurationApiService.getRoomcloudInformation({
            reservation_config_id: generalReservationConfigId,
          });
        const configurationResponse = response.data.roomcloud_information;
        const roomcloudInformation = {
          hotelId: configurationResponse?.hotel_id,
          roomId: configurationResponse?.room_id,
          roomName: configurationResponse?.room_name,
          roomprice: configurationResponse?.room_price,
          rateId: configurationResponse?.rate_id,
          nigths: configurationResponse?.nigths,
          checkinTime: configurationResponse?.checkin_time,
          isCompleteConfiguration:
            configurationResponse?.is_complete_configuration,
        };
        this.roomcloudInformation = roomcloudInformation;
      },

      async getWonomaInformation(generalReservationConfigId: string) {
        const response =
          await ReservationConfigurationApiService.getWonomaInformation({
            reservation_config_id: generalReservationConfigId,
          });
        const configurationResponse = response.data.wonoma_information;
        const wonomaInformation = {
          service: configurationResponse.service,
          provider_id: configurationResponse.provider_id,
          is_provider_verified: configurationResponse.is_provider_verified,
          allow_cancel: configurationResponse.allow_cancel,
          hours_to_cancel: configurationResponse.hours_to_cancel,
          is_wonoma_reservation: configurationResponse.is_wonoma_reservation,
          is_complete_configuration:
            configurationResponse.is_complete_configuration,
        };
        this.wonomaInformation = wonomaInformation;
      },
      async getWokiInformation(generalReservationConfigId: string) {
        const response =
          await ReservationConfigurationApiService.getWokiInformation({
            reservation_config_id: generalReservationConfigId,
          });
        const configurationResponse = response.data.woki_information;
        const wokiInformation = {
          provider_id: configurationResponse.provider_id,
          is_woki_reservation: configurationResponse.is_woki_reservation,
          is_complete_configuration:
            configurationResponse.is_complete_configuration,
          zones: configurationResponse.zones,
        };
        this.wokiInformation = wokiInformation;
      },

      async getWonomaServices(providerId: string) {
        const response =
          await ReservationConfigurationApiService.getWonomaServices({
            provider_id: providerId,
          });

        const wonomaServices = response.data
          .services_by_provider_id as WonomaServices[];

        this.wonomaServices = wonomaServices;
      },

      async getCopyableLocations(generalReservationConfigId: string) {
        const response =
          await ReservationConfigurationApiService.getCopyableLocations({
            general_reservation_config_id: generalReservationConfigId,
          });

        const locations =
          response.data.partners_copyable_locations_configuration;
        this.copyableLocations = locations;
      },

      setSelectedConfiguration(selectedConfiguration: PartnerConfiguration) {
        this.selectedReservationConfiguration = selectedConfiguration;
        const orderedDays = selectedConfiguration.days_reservation_availability;

        const daysWithId = orderedDays.map(day => {
          return {
            ...day,
            appointments: day.appointments.map(appt => {
              return {
                ...appt,
                localId: idGenerator.next().value as number,
              };
            }),
          };
        });
        this.selectedAvailableDaysAndTimeSlots = daysWithId;
      },

      setDayEnabledProperty(id: string) {
        const index = this.selectedAvailableDaysAndTimeSlots.findIndex(
          day => day.id === id,
        );
        this.selectedAvailableDaysAndTimeSlots[index].enabled =
          !this.selectedAvailableDaysAndTimeSlots[index].enabled;
      },

      setLocationSelected({ locationId }: { locationId: string }) {
        this.locationSelected = locationId;
      },

      setActivityBranch({
        activityLocation,
      }: {
        activityLocation: ActivityLocation;
      }) {
        this.activityLocationSelected = { ...activityLocation };
      },

      setDayCopy({
        dayIndex,
        appointmentsToCopy,
      }: {
        dayIndex: number;
        appointmentsToCopy: Appointment[];
      }) {
        const copiedAppointments = appointmentsToCopy.map(appointment => {
          return {
            time: appointment.time,
            end_time: appointment.end_time,
            quotas: appointment.quotas,
            delete: false,
            localId: idGenerator.next().value as number,
          };
        });

        this.selectedAvailableDaysAndTimeSlots[dayIndex].appointments.push(
          ...copiedAppointments,
        );
      },

      setAvailableTimeSlot(dayId: string) {
        const index = this.selectedAvailableDaysAndTimeSlots.findIndex(
          item => item.id === dayId,
        );
        const timeSlotInfo = {
          time: '',
          end_time: null,
          quotas: 1,
          delete: false,
          localId: idGenerator.next().value as number,
        };
        this.selectedAvailableDaysAndTimeSlots[index].appointments.push(
          timeSlotInfo,
        );
      },

      async createConfiguration() {
        const response =
          await ReservationConfigurationApiService.createPanelConfiguration({
            gral_reservation_config_id: this.generalReservationConfigId,
          });
        this.newConfigurationId =
          response.data?.create_special_configuration.configuration.id ?? null;
      },
      updateAvailableTimeSlot({
        timeSlotInfo,
        index,
      }: {
        timeSlotInfo: LocalAppointment;
        index: number;
      }) {
        const appointments =
          this.selectedAvailableDaysAndTimeSlots[index].appointments;
        const appointmentIndex = appointments.findIndex(
          item => item.localId === timeSlotInfo.localId,
        );
        appointments[appointmentIndex] = timeSlotInfo;
      },
      setAvailableTimeSlotDeleteProperty({
        localId,
        dayIndex,
      }: {
        localId: number;
        dayIndex: number;
      }) {
        const appointments =
          this.selectedAvailableDaysAndTimeSlots[dayIndex].appointments;
        const index = appointments.findIndex(item => item.localId === localId);
        appointments[index] = {
          ...appointments[index],
          delete: true,
        };
      },
      resetConfigurationPanelData() {
        this.selectedReservationConfiguration = null;
        this.selectedAvailableDaysAndTimeSlots = [];
      },
      setReservationKind(payload: IReservationKind) {
        this.reservationKind = payload;
      },
      setContactInformation(payload: IContactInformation) {
        this.contactInformation = payload;
      },
      setConditions(payload: IConditions) {
        this.conditions = payload;
      },
    },
    getters: {
      totalQuotas: state => {
        /*Sum of all appointments of all available days, excluding appts with
     property 'delete: true' and property 'time: null'*/
        return state.selectedAvailableDaysAndTimeSlots.reduce(
          (accumulator, currentDay) => {
            if (currentDay.enabled) {
              return (
                accumulator +
                currentDay.appointments.reduce(
                  (accumulator, currentAppointment) => {
                    if (!currentAppointment.delete && currentAppointment.time) {
                      return accumulator + Number(currentAppointment.quotas);
                    }
                    return accumulator;
                  },
                  0,
                )
              );
            }
            return accumulator;
          },
          0,
        );
      },
      weekendQuota: state => {
        if (!state.policySchema.weekend_quota) {
          return true;
        }
        const dayNumbers = ['5', '6']; //day_number of friday and saturday
        // gets dayNumbers days, if they are enabled
        const weekendDays = state.selectedAvailableDaysAndTimeSlots.filter(
          day => dayNumbers.includes(day.day_number) && day.enabled,
        );
        if (!weekendDays.length) {
          return false;
        }
        //checks if weekendDays have appointments that aren't going to be deleted
        return weekendDays.some(day =>
          day.appointments.some(appointment => !appointment.delete),
        );
      },
    },
  },
);
