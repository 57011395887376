// Queries
import AVAILABLE_METHODS from '@/assets/graphql/queries/reservationConfiguration/AvailableMethods.graphql';
import COMPLETED_FIELDS from '@/assets/graphql/queries/reservationConfiguration/CompletedFields.graphql';
import CONDITIONS from '@/assets/graphql/queries/reservationConfiguration/Conditions.graphql';
import CONTACT_INFORMATION from '@/assets/graphql/queries/reservationConfiguration/ContactInformation.graphql';
import COPYABLE_LOCATIONS from '@/assets/graphql/queries/reservationConfiguration/CopyableLocations.graphql';
import COVERMANAGER_INFORMATION from '@/assets/graphql/queries/reservationConfiguration/CovermanagerInformation.graphql';
import ROOMCLOUD_INFORMATION from '@/assets/graphql/queries/reservationConfiguration/RoomcloudInformation.graphql';
import WONOMA_INFORMATION from '@/assets/graphql/queries/reservationConfiguration/WonomaInformation.graphql';
import WONOMA_SERVICES from '@/assets/graphql/queries/reservationConfiguration/WonomaServices.graphql';
import EXTERNAL_FORMS from '@/assets/graphql/queries/reservationConfiguration/ExternalForms.graphql';
import EXTERNAL_LOCATION from '@/assets/graphql/queries/reservationConfiguration/ExternalLocation.graphql';
import HAS_PARTNERS_GENERIC_RESERVATION_CONFIGURATION from '@/assets/graphql/queries/reservationConfiguration/HasPartnersGenericReservationConfiguration.graphql';
import PANEL_CONFIGURATION_ACTIVITIES from '@/assets/graphql/queries/reservationConfiguration/PanelConfigurationActivities.graphql';
import PARTNER_POLICY_SCHEMA from '@/assets/graphql/queries/reservationConfiguration/PartnerPolicySchema.graphql';
import RESERVATION_KIND from '@/assets/graphql/queries/reservationConfiguration/ReservationKind.graphql';
import RESERVATION_METHODS from '@/assets/graphql/queries/reservationConfiguration/ReservationMethods.graphql';
import CHECK_ACTIVITY_LOCATIONS from '@/assets/graphql/queries/reservationConfiguration/CheckActivityLocations.graphql';
import INITIAL_RESERVATION_CONFIGURATION from '@/assets/graphql/queries/reservationConfiguration/InitialReservationConfiguration.graphql';
import INITIAL_RESERVATION_CONFIGURATION_NEW from '@/assets/graphql/queries/reservationConfiguration/ReservationConfigurationStatus.graphql';
import PARTNER_CONFIGURATIONS from '@/assets/graphql/queries/reservationConfiguration/PartnerConfigurations.graphql';
import WOKI_ZONES from '@/assets/graphql/queries/reservationConfiguration/WokiZones.graphql';

//  Mutations

import CHECK_WHATSAPP from '@/assets/graphql/mutations/reservationConfiguration/CheckWhatsapp.graphql';
import COPY_LOCATIONS_CONFIGURATIONS from '@/assets/graphql/mutations/reservationConfiguration/CopyLocationsConfigurations.graphql';
import EXTERNAL_RESERVATION_REQUEST from '@/assets/graphql/mutations/reservationConfiguration/ExternalReservationRequest.graphql';
import CREATE_CONFIGURATION from '@/assets/graphql/mutations/reservationConfiguration/CreateConfiguration.graphql';
import DELETE_SPECIAL_CONFIGURATION from '@/assets/graphql/mutations/reservationConfiguration/DeleteSpecialConfiguration.graphql';
import UPDATE_CONDITIONS from '@/assets/graphql/mutations/reservationConfiguration/UpdateConditions.graphql';
import UPDATE_CONTACT_INFORMATION from '@/assets/graphql/mutations/reservationConfiguration/UpdateContactInformation.graphql';
import UPDATE_COVERMANAGER_RESERVATION from '@/assets/graphql/mutations/reservationConfiguration/UpdateCovermanagerReservation.graphql';
import UPDATE_RESERVATION_METHODS from '@/assets/graphql/mutations/reservationConfiguration/UpdateReservationServiceStatus.graphql';
import UPDATE_ROOMCLOUD_RESERVATION from '@/assets/graphql/mutations/reservationConfiguration/UpdateRoomCloudReservation.graphql';
import UPDATE_PARTNER_APPOINTMENTS_AVAILABILITY from '@/assets/graphql/mutations/reservationConfiguration/UpdatePartnerAppointmentsAvailability.graphql';
import UPDATE_PARTNER_DAY_BLOCK from '@/assets/graphql/mutations/reservationConfiguration/UpdatePartnerDayBlock.graphql';
import UPDATE_PARTNER_DAYS_ONLINE_RESERVATION from '@/assets/graphql/mutations/reservationConfiguration/UpdatePartnerDaysOnlineReservation.graphql';
import UPDATE_RESERVATION_CONFIGURATION from '@/assets/graphql/mutations/reservationConfiguration/UpdateReservationConfiguration.graphql';
import UPDATE_RESERVATION_KIND from '@/assets/graphql/mutations/reservationConfiguration/UpdateReservationKind.graphql';
import UPDATE_RESERVATION_SERVICE_PROVIDER from '@/assets/graphql/mutations/reservationConfiguration/UpdateReservationServiceProvider.graphql';
import UPDATE_WONOMA_CONFIGURATION from '@/assets/graphql/mutations/reservationConfiguration/UpdateWonomaConfiguration.graphql';
import UPDATE_SPECIAL_CONFIGURATION from '@/assets/graphql/mutations/reservationConfiguration/UpdateSpecialConfiguration.graphql';
import VERIFY_COVERMANAGER_SLUG from '@/assets/graphql/mutations/reservationConfiguration/VerifyCovermanagerSlug.graphql';
import VERIFY_WONOMA_ID from '@/assets/graphql/mutations/reservationConfiguration/VerifyWonomaId.graphql';
import CREATE_BIGBOX_RESERVATION from '@/assets/graphql/mutations/reservationConfiguration/CreateBigboxReservation.graphql';
import VERIFY_WOKI_ID from '@/assets/graphql/mutations/reservationConfiguration/VerifyWokiId.graphql';
import UPDATE_WOKI_CONFIGURATION from '@/assets/graphql/mutations/reservationConfiguration/UpdateWokiConfiguration.graphql';
import WOKI_INFORMATION from '@/assets/graphql/queries/reservationConfiguration/WokiInformation.graphql';

import apolloService from '@/services/apolloService';

import * as Types from './types';

export default class {
  //QUERIES
  static getAvailableReservationMethods(variables: {
    partner_location_id: number;
  }) {
    return apolloService.query<Types.AvailableMethodsResponse>(
      AVAILABLE_METHODS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getCompletedFields(variables: {
    general_reservation_config_id: string;
  }) {
    return apolloService.query<Types.CompletedFieldsResponse>(
      COMPLETED_FIELDS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getConditions(variables: {
    partneractivity_id: string;
    location_id: string;
  }) {
    return apolloService.query<Types.ConditionsResponse>(
      CONDITIONS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getContactInformation(variables: {
    partneractivity_id: string;
    location_id: string;
  }) {
    return apolloService.query<Types.ContactInformationResponse>(
      CONTACT_INFORMATION,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getCopyableLocations(variables: {
    general_reservation_config_id: string;
  }) {
    return apolloService.query<Types.CopyableLocationsResponse>(
      COPYABLE_LOCATIONS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getCovermanagerInformation(variables: {
    reservation_config_id: string;
  }) {
    return apolloService.query<Types.CovermanagerInformationResponse>(
      COVERMANAGER_INFORMATION,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getRoomcloudInformation(variables: { reservation_config_id: string }) {
    return apolloService.query<Types.RoomcloudInformationResponse>(
      ROOMCLOUD_INFORMATION,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getWonomaInformation(variables: { reservation_config_id: string }) {
    return apolloService.query<Types.WonomaInformationResponse>(
      WONOMA_INFORMATION,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getWokiInformation(variables: { reservation_config_id: string }) {
    return apolloService.query<Types.WokiInformationResponse>(
      WOKI_INFORMATION,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getWonomaServices(variables: { provider_id: string }) {
    return apolloService.query<Types.WonomaServicesResponse>(
      WONOMA_SERVICES,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getExternalForms() {
    return apolloService.query<Types.ExternalFormsResponse>(
      EXTERNAL_FORMS,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getExternalLocation(variables: { location_id: number }) {
    return apolloService.query<Types.ExternalReservationLocationsResponse>(
      EXTERNAL_LOCATION,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getHasPartnersGenericReservationConfiguration() {
    return apolloService.query(
      HAS_PARTNERS_GENERIC_RESERVATION_CONFIGURATION,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getPanelConfigurationActivities() {
    return apolloService.query<Types.PanelConfigurationActivitiesResponse>(
      PANEL_CONFIGURATION_ACTIVITIES,
    );
  }
  static getPolicySchema(variables: { general_reservation_config_id: string }) {
    return apolloService.query<Types.PartnerPolicySchemaResponse>(
      PARTNER_POLICY_SCHEMA,
      variables,
    );
  }

  static getReservationKind(variables: {
    partneractivity_id: string;
    location_id: string;
  }) {
    return apolloService.query<Types.ReservationKindResponse>(
      RESERVATION_KIND,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getReservationMethods(variables: { partner_location_id: number }) {
    return apolloService.query<Types.ReservationMethodsResponse>(
      RESERVATION_METHODS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static checkActivityLocations(variables: { activity: string }) {
    return apolloService.query<Types.ActivityLocationsResponse>(
      CHECK_ACTIVITY_LOCATIONS,
      variables,
    );
  }
  static getInitialReservationConfiguration(variables: {
    partneractivity_id: string;
    location_id: string;
  }) {
    return apolloService.query<Types.InitialConfigurationResponse>(
      INITIAL_RESERVATION_CONFIGURATION,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static initialReservationConfiguration(variables: {
    partneractivity_id: string;
    location_id: string;
  }) {
    return apolloService.query<Types.ReservationMethodsConfigurationResponse>(
      INITIAL_RESERVATION_CONFIGURATION_NEW,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getConfigurations(variables: { reservation_config_id: string }) {
    return apolloService.query<Types.PartnerConfigurationsResponse>(
      PARTNER_CONFIGURATIONS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  //  MUTATIONS

  static checkWhatsapp(variables: Types.CheckWhatsappPayload) {
    return apolloService.mutate<Types.CheckWhatsappResponse>(
      CHECK_WHATSAPP,
      variables,
    );
  }
  static copyLocationsConfigurations(
    variables: Types.CopyLocationsConfigurationsPayload,
  ) {
    return apolloService.mutate<Types.CopyLocationsConfigurationsResponse>(
      COPY_LOCATIONS_CONFIGURATIONS,
      variables,
    );
  }
  static sendExternalReservationRequest(variables: any) {
    return apolloService.mutate<Types.SendReservationMethodRequestResponse>(
      EXTERNAL_RESERVATION_REQUEST,
      variables,
    );
  }

  static createPanelConfiguration(variables: {
    gral_reservation_config_id: string;
  }) {
    return apolloService.mutate<Types.CreateConfigurationResponse>(
      CREATE_CONFIGURATION,
      variables,
    );
  }
  static deleteSpecialConfiguration(variables: { configuration_id: string }) {
    return apolloService.mutate<Types.DeleteSpecialConfigurationResponse>(
      DELETE_SPECIAL_CONFIGURATION,
      variables,
    );
  }
  static updateConditions(variables: Types.UpdatedConditionsPayload) {
    return apolloService.mutate<Types.UpdatedConditionsResponse>(
      UPDATE_CONDITIONS,
      variables,
    );
  }
  static updateContactInformation(
    variables: Types.UpdateContactInformationPayload,
  ) {
    return apolloService.mutate<Types.UpdateContactInformationResponse>(
      UPDATE_CONTACT_INFORMATION,
      variables,
    );
  }
  static updateCoverManagerReservation(
    variables: Types.UpdateCovermanagerReservationPayload,
  ) {
    return apolloService.mutate<Types.UpdateCovermanagerReservationResponse>(
      UPDATE_COVERMANAGER_RESERVATION,
      variables,
    );
  }
  static updateWonomaConfiguration(
    variables: Types.UpdateWonomaConfigurationPayload,
  ) {
    return apolloService.mutate<Types.UpdateWonomaConfigurationResponse>(
      UPDATE_WONOMA_CONFIGURATION,
      variables,
    );
  }

  static updateWokiConfiguration(
    variables: Types.UpdateWokiConfigurationPayload,
  ) {
    return apolloService.mutate<Types.UpdateWokiConfigurationResponse>(
      UPDATE_WOKI_CONFIGURATION,
      variables,
    );
  }

  static updateReservationStatus(
    variables: Types.UpdateReservationMethodsPayload,
  ) {
    return apolloService.mutate<Types.UpdateReservationMethodsResponse>(
      UPDATE_RESERVATION_METHODS,
      variables,
    );
  }
  static updateRoomcloudReservation(
    variables: Types.UpdateRoomcloudReservationPayload,
  ) {
    return apolloService.mutate<Types.UpdateRoomcloudReservationResponse>(
      UPDATE_ROOMCLOUD_RESERVATION,
      variables,
    );
  }

  static updatePartnerAppointmendsAvailability(variables: any) {
    return apolloService.mutate(
      UPDATE_PARTNER_APPOINTMENTS_AVAILABILITY,
      variables,
    );
  }
  static updatePartnerDayBlock(variables: any) {
    return apolloService.mutate(UPDATE_PARTNER_DAY_BLOCK, variables);
  }
  static updatePartnerDaysOnlineReservation(variables: any) {
    return apolloService.mutate(
      UPDATE_PARTNER_DAYS_ONLINE_RESERVATION,
      variables,
    );
  }
  static updateReservationConfiguration(variables: {
    panel_configuration: Types.UpdateReservationConfigurationPayload;
  }) {
    return apolloService.mutate<Types.UpdateReservationConfigurationResponse>(
      UPDATE_RESERVATION_CONFIGURATION,
      variables,
    );
  }
  static updateReservationKind(variables: Types.UpdateReservationPayload) {
    return apolloService.mutate<Types.UpdateReservationResponse>(
      UPDATE_RESERVATION_KIND,
      variables,
    );
  }
  static updateReservationServiceProvider(
    variables: Types.UpdateReservationServicePayload,
  ) {
    return apolloService.mutate<Types.UpdateReservationServiceResponse>(
      UPDATE_RESERVATION_SERVICE_PROVIDER,
      variables,
    );
  }
  static updateSpecialConfiguration(variables: any) {
    return apolloService.mutate(UPDATE_SPECIAL_CONFIGURATION, variables);
  }
  static verifyCoverManagerSlug(
    variables: Types.VerifyCovermanagerSlugPayload,
  ) {
    return apolloService.mutate<Types.VerifyCovermanagerSlugResponse>(
      VERIFY_COVERMANAGER_SLUG,
      variables,
    );
  }

  static verifyWonomaId(variables: Types.VerifyWonomaIdPayload) {
    return apolloService.mutate<Types.VerifyWonomaIdResponse>(
      VERIFY_WONOMA_ID,
      variables,
    );
  }

  static verifyWokiId(variables: Types.VerifyWokiIdPayload) {
    return apolloService.mutate<Types.VerifyWokiIdResponse>(
      VERIFY_WOKI_ID,
      variables,
    );
  }

  static createBigboxReservation(variables: Types.CreateReservationPayload) {
    return apolloService.mutate<Types.CreateReservationResponse>(
      CREATE_BIGBOX_RESERVATION,
      variables,
    );
  }

  static getWokiZones(variables: { provider_id: string }) {
    return apolloService.query<Types.WokiZonesResponse>(WOKI_ZONES, variables, {
      fetchPolicy: 'network-only',
    });
  }
}
